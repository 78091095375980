@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #0f253f;
}

/* Highlight Text */
::-moz-selection {
  @apply bg-blue-300;
}
::-webkit-selection {
  @apply bg-blue-300;
}
::selection {
  @apply bg-blue-300;
}

/* Scroll Bar */
::-webkit-scrollbar {
  display: none;
}

/* Font */
:root {
  font-family: Inter, sans-serif;
  font-feature-settings: 'liga' 1, 'calt' 1; /* fix for Chrome */
}
@supports (font-variation-settings: normal) {
  :root { font-family: InterVariable, sans-serif; }
}

/* Classes */
.me_social_media_icons {
  @apply text-slate-400 hover:text-slate-200 duration-200;
}

.about_bold_importance {
  @apply text-slate-200 hover:text-blue-300 duration-300 font-semibold;
}

.skill_theme {
  @apply text-blue-300 bg-blue-300/10 px-3 py-1.5 rounded-full text-xs font-medium mr-1.5 mt-2;
}

.app_module_format {
  @apply mx-6 pb-24;
}

.header {
  @apply text-slate-200 font-semibold text-sm mb-10 uppercase;
}

.built_highlight{
  @apply font-medium text-slate-400 hover:text-blue-300 duration-300;
}
